<template>
  <van-form @failed="onFailed" @submit="submit">
    <div class="about">
      <van-cell-group title="人员信息">
        <van-field
          v-model="personInfo.idCard"
          name="身份证号"
          label="身份证号"
          placeholder="身份证号"
          @blur="getPersonBaseInfo"
          :rules="[{ required: true, message: '身份证号' }]"
        />

        <van-field
          v-model="personInfo.personName"
          name="姓名"
          label="姓名"
          placeholder="姓名"
          :rules="[{ required: true, message: '请填写姓名' }]"
        />

        <van-cell title="性别" :value="personInfo.sex" />
        <van-cell title="年龄" :value="personInfo.age" />

        <van-field
          v-model="personInfo.phone"
          name="联系电话"
          label="联系电话"
          placeholder="联系电话"
          :rules="[{ required: true, message: '联系电话' }]"
        />

        <van-field
          v-model="personInfo.workUnit"
          name="工作单位"
          label="工作单位"
          placeholder="工作单位"
        />
        <van-field
          v-model="personInfo.address"
          name="现居住地"
          label="现居住地"
          placeholder="现居住地"
          :rules="[{ required: true, message: '现居住地' }]"
        />

        <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="所在地区"
          placeholder="请选择所在地区"
          @click="show = true"
          :rules="[{ required: true, message: '所在地区' }]"
        />
        <van-popup v-model:show="show" round position="bottom">
          <van-cascader
            v-model="personInfo.houseId"
            title="请选择所在地区"
            :field-names="fieldNames"
            :options="communityList"
            @close="show = false"
            @change="onChange"
            @finish="onFinish"
          />
        </van-popup>

        <van-field
          v-model="personInfo.disease"
          name="基础疾病情况"
          label="基础疾病情况"
          placeholder="请输入"
        />
        <van-field
          v-model="
            dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
          "
          is-link
          readonly
          name="picker"
          label="人员类型"
          placeholder="点击选择人员类型"
          @click="showPicker = true"
          :rules="[{ required: true, message: '人员类型' }]"
        />
        <van-popup v-model:show="showPicker" position="bottom">
          <van-picker
            value-key="value"
            :columns="dicPersonTypeLabel"
            @confirm="
              (value) => {
                personInfo.personType =
                  dicPersonType[dicPersonTypeLabel.indexOf(value)];
                showPicker = false;
              }
            "
            :default-index="dicPersonType.indexOf(personInfo.personType)"
            @cancel="showPicker = false"
          />
        </van-popup>
      </van-cell-group>
      <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
      <!-- 红黄码人员 需填写 安康码、行程码-->
      <!-- 其他情况 需填写  其他说明、安康码、行程码-->

      <van-cell-group
        :title="
          dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]
        "
      >
        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) >= 7"
          v-model="personInfo.touchCase"
          name="密接病例"
          label="密接病例"
          placeholder="密接病例"
          :rules="[{ required: true, message: '密接病例' }]"
        />
        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) >= 7"
          v-model="personInfo.releaseMode"
          name="接触方式"
          label="接触方式"
          placeholder="接触方式"
          :rules="[{ required: true, message: '接触方式' }]"
        />

        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) >= 6"
          v-model="personInfo.lastContactDate"
          name="末次密接时间"
          label="末次密接时间"
          placeholder="末次密接时间"
          :rules="[{ required: true, message: '末次密接时间' }]"
          @click="
            showDatetimePicker = true;
            DatetimePickerField = 'lastContactDate';
          "
        />

        <van-popup v-model:show="showDatetimePicker" position="bottom">
          <van-datetime-picker
            type="datetime"
            @confirm="
              (value) => {
                personInfo[DatetimePickerField] = timeFormat(value);
                showDatetimePicker = false;
              }
            "
            @cancel="showDatetimePicker = false"
          />
        </van-popup>

        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
          v-model="personInfo.returnDate"
          name="返回合肥时间"
          label="返回合肥时间"
          :rules="[{ required: true, message: '返回合肥时间' }]"
          placeholder="请输入返回合肥时间"
          @click="
            showDatetimePicker = true;
            DatetimePickerField = 'returnDate';
          "
        />

        <van-field
          v-model="personInfo.returnRegionStr"
          is-link
          readonly
          v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
          name="area"
          label="返回前所在地区"
          placeholder="返回前所在地区"
          :rules="[{ required: true, message: '返回前所在地区' }]"
          @click="showArea = true"
        />
        <van-popup v-model:show="showArea" position="bottom">
          <van-area
            :area-list="areaList"
            @confirm="(data) => onConfirm(data, 'returnRegion')"
            @cancel="showArea = false"
          />
        </van-popup>

        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
          v-model="personInfo.vehicle"
          name="交通工具"
          label="交通工具"
          :rules="[{ required: true, message: '交通工具' }]"
          placeholder="交通工具"
        />
        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
          v-model="personInfo.flightNumber"
          name="航班车次号"
          label="航班车次号"
          :rules="[{ required: true, message: '航班车次号' }]"
          placeholder="航班车次号"
        />

        <van-cell
          title="安康码"
          v-if="dicPersonType.indexOf(personInfo.personType) >= 1"
        >
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="personInfo.ankangCodeList"
              :after-read="(file) => afterRead(file, 'ankangCode')"
              max-count="1"
            />
          </template>
        </van-cell>
        <van-cell
          title="行程码"
          v-if="dicPersonType.indexOf(personInfo.personType) >= 1"
        >
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="personInfo.tripCodeList"
              :after-read="(file) => afterRead(file, 'tripCode')"
              max-count="1"
            />
          </template>
        </van-cell>
        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) == 5"
          v-model="personInfo.isolatedCity"
          name="隔离城市"
          label="隔离城市"
          :rules="[{ required: true, message: '隔离城市' }]"
          placeholder="隔离城市"
        />

        <van-field
          v-if="dicPersonType.indexOf(personInfo.personType) == 5"
          v-model="personInfo.isolatedHotel"
          name="隔离酒店"
          label="隔离酒店"
          :rules="[{ required: true, message: '隔离酒店' }]"
          placeholder="隔离酒店"
        />
        <van-cell
          title="核酸检测照片"
          v-if="dicPersonType.indexOf(personInfo.personType) == 5"
        >
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="personInfo.nucleinImgList"
              :after-read="(file) => afterRead(file, 'nucleinImg')"
              max-count="1"
            />
          </template>
        </van-cell>
        <van-cell
          title="解除隔离证明"
          v-if="dicPersonType.indexOf(personInfo.personType) == 5"
        >
          <template #value>
            <van-uploader
              width="100"
              height="100"
              v-model="personInfo.isolatedProveList"
              :after-read="(file) => afterRead(file, 'isolatedProve')"
              max-count="1"
            />
          </template>
        </van-cell>

        <van-field
          v-model="personInfo.remark"
          rows="2"
          autosize
          label="其他说明"
          type="textarea"
          maxlength="50"
          placeholder="请输入其他说明"
          show-word-limit
        />
      </van-cell-group>
    </div>
    <div style="margin: 16px">
      <van-button round block type="primary" native-type="submit">
        提交
      </van-button>
    </div>
  </van-form>
</template>

<script>
import { ref } from '@vue/reactivity'
// @ is an alias to /src 
import { useRouter } from 'vue-router'
import { ImagePreview, Notify } from 'vant';
import Api from '../util/App'
export default {
  name: 'Home',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {

    const personInfo = ref({
      "address": "",
      "age": 0,
      "ankangCode": "",
      "bulidingId": "",
      "community": "",
      "dataSoucre": "",
      "disease": "",
      "flightNumber": "",
      "grid": "",
      "gridId": 0,
      "houseCode": "",
      "houseId": "",
      "idCard": "",
      "isolatedCity": "",
      "isolatedHotel": "",
      "isolatedProve": "",
      "lastContactDate": "",
      "nucleinImg": "",
      "permanentAddress": "",
      "personName": "",
      "personType": "",
      "phone": "",
      "releaseMode": "",
      "remark": "",
      "returnDate": "",
      "returnRegion": 0,
      "sex": "",
      "touchCase": "",
      "tripCode": "",
      "vehicle": "",
      "workUnit": ""
    })

    const dicPersonTypeLabel = ["其他情况", "红黄码人员", "中高风险地区返回", "市外返回", "省外返回", "境外人员", "阳性物品暴露人员", "次密接", "密接"]

    const dicPersonType = ['Other', 'RedAndYellow', 'HighRisk', "OutsideCity", "OutsideProvince", "Abroad", 'PositiveExposure', 'SecondTouch', 'Touch']

    const dicPersonTypeList = [{ value: 'Other', label: '其他情况' }, { value: 'RedAndYellow', label: '红黄码人员' }, { value: 'HighRisk', label: '中高风险地区返回' }, { value: 'OutsideCity', label: '市外返回' }, { value: 'OutsideProvince', label: '省外返回' }, { value: 'Abroad', label: '境外人员' }, { value: 'PositiveExposure', label: '阳性物品暴露人员' }, { value: 'SecondTouch', label: '次密接' }, { value: 'Touch', label: '密接' },]
    const showPicker = ref(false);
    const showDatetimePicker = ref(false);
    const DatetimePickerField = ref(false);
    const router = useRouter()
    const showArea = ref(false);
    const areaList = ref({
      province_list: {

      },
      city_list: {

      },
      county_list: {

      },
    })
    const show = ref(false);
    const fieldValue = ref('');
    const cascaderValue = ref('');
    const communityList = ref([
      {
        "id": "3401044000010000",
        "pid": "1067246875800000066",
        "children": [],
        "name": "万泽社区",
        "lon": null,
        "lat": null,
        "sort": 1,
        "createDate": null,
        "parentName": null,
        "remark": null,
        "isDelete": 0,
        "userLists": null
      }
    ]);
    const fieldNames = {
      text: 'name',
      value: 'id',

    };
    const DataInfo = ref(
      {
        returnRegion: '',
        Community: '',
        Live: '',
        Store: '',
        House: ''


      })

    return { router, personInfo, dicPersonType, dicPersonTypeLabel, ImagePreview, showPicker, showDatetimePicker, DatetimePickerField, dicPersonTypeList, show, fieldValue, cascaderValue, communityList, fieldNames, showArea, areaList, DataInfo }
  },
  mounted() {
    this.$emit('changeTitle', "居家隔离人员详情",)
    let id = this.router.currentRoute.value.query.id;
    this.initdata(id)

  },
  methods:
  {
    initdata(id) {
      if (id != null && id != "") {
        Api.getPersoninfo(id).then(res => {
          this.personInfo = res.data
          this.getCommunityList()
          this.getRegionList()
          this.personInfo.tripCodeList = [this.personInfo.tripCode]
          this.personInfo.ankangCodeList = [this.personInfo.ankangCode]
          this.getRegion();
          this.getCommunityLive()
        })

      }

    },
    getRegion() {
      if (this.personInfo.returnRegion == '') return ''
      Api.getRegionList().then(res => {

try{
        let list = res.data;
        let county = list.find(p => p.id == this.personInfo.returnRegion);
        let city = list.find(p => p.id == county.pid);
        let province = list.find(p => p.id == city.pid);

        this.DataInfo.returnRegion = province.name + "/" + city.name + "/" + county.name;
        }catch{
             // continue regardless of error
        }
      })

    },
    getCommunityLive() {

      Api.getOrgCommunityList().then(res => {
        this.communityList = res.data
        this.DataInfo.Community = this.communityList.find(p => p.id == this.personInfo.community).name
      })
      Api.getOrgLiveList({ communityId: this.personInfo.community }).then(res => {
        this.DataInfo.Live = res.data.find(p => p.id == this.personInfo.liveId).name
      })

      Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(res => {

        this.DataInfo.Store = res.data.find(p => p.id == this.personInfo.bulidingId).name
      })

      Api.getOrgPublicPage({ storeId: this.personInfo.bulidingId }).then(res => {

        this.DataInfo.House = res.data.find(p => p.id == this.personInfo.houseId).name
      })
    },
    getRegionList() {
      Api.getRegionList().then(res => {
        let list = res.data;
        let ProvinceList = list.filter(p => p.pid == 0);
        ProvinceList.forEach(province => {
          this.areaList.province_list[province.id] = province.name;
          let cityList = list.filter(p => p.pid == province.id);
          cityList.forEach(city => {
            this.areaList.city_list[city.id] = city.name;
            let countyList = list.filter(p => p.pid == city.id);
            countyList.forEach(county => {
              this.areaList.county_list[county.id] = county.name;
            });
          });
        });
      })
    },
    onConfirm(data, field) {

      this.showArea = false;
      this.personInfo[field] = data[data.length - 1].code
      this.personInfo.returnRegionStr = data.map((item) => item.name).join('/');
    },
    getPersonBaseInfo() {
      let idCard = this.personInfo.idCard
      if (idCard != null && idCard != "") {
        let agesex = this.GetAgeSex(this.personInfo.idCard);
        this.personInfo.sex = agesex.sex
        this.personInfo.age = agesex.age
        Api.getOrgPersonBaseInfo(this.personInfo.idCard).then(res => {
          let baseInfo = res.data;



          this.personInfo.personName = baseInfo.name
          this.personInfo.phone = baseInfo.phone
          this.personInfo.address = baseInfo.address

          this.personInfo.gridId = baseInfo.gridId
          this.personInfo.houseId = baseInfo.houseId
          this.personInfo.bulidingId = baseInfo.bulidingId
          this.personInfo.community = baseInfo.community
          this.personInfo.liveId = baseInfo.liveId
          this.personInfo.permanentAddress = baseInfo.permanentAddress

          this.personInfo.idCard = idCard


          this.getCommunityAllList()

        })
      }
    },
    GetAgeSex(identityCard) {
      let len = (identityCard + "").length;
      let strBirthday = "";
      let strSex = "";
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);

        if (identityCard.charAt(16) >= '0' && identityCard.charAt(16) <= '9') {
          if (parseInt(identityCard.charAt(16)) % 2 === 0) {
            strSex = '女'
          } else {
            strSex = '男'
          }
        }

      }
      if (len == 15) {
        let birthdayValue = "";
        birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
        if (parseInt(birthdayValue) < 10) {
          strBirthday = "20" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        } else {
          strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        }

        if (identityCard.substr(14) >= '0' && identityCard.substr(14) <= '9') {
          if (parseInt(identityCard.charAt(14) % 2 === 0)) {
            strSex = '女'
          } else {
            strSex = '男'
          }
        }

      }
      let birthDate = new Date(strBirthday);
      let nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return { age: isNaN(age) ? '' : age, sex: strSex };
    },
    afterRead(file, field) {

      //上传文件、成功后， 把Url 赋值给 field  

    },
    timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + '-' + this.padLeft(month, 2) + '-' + this.padLeft(day, 2) + " " + this.padLeft(hour, 2) + ":" + this.padLeft(min, 2) + ":00"
    },
    padLeft(num, n) {
      let y = '00000000000000000000000000000' + num; //爱几个0就几个，自己够用就行  
      return y.substr(y.length - n);
    },

    getCommunityList() {
      this.communityList = [];
      Api.getOrgCommunityList().then(res => {
        this.communityList = res.data
        //   for (let index = 0; index < res.data.length; index++) {
        //       const item = res.data[index];
        //            this.communityList.push({text:item.name,value:item.id,children:item.children}) 
        //   } 
      })
    },
    getCommunityAllList() {

      Api.getOrgLiveList({ communityId: this.personInfo.community }).then(res => {

        res.data.forEach(element => {
          element["children"] = []
        });
        this.communityList.find(p => p.id == this.personInfo.community)["children"] = res.data

        Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(res => {

          res.data.forEach(element => {
            element["children"] = []
          });
          this.communityList.find(p => p.id == this.personInfo.community).children.find(p => p.id == this.personInfo.liveId).children = res.data
          Api.getOrgPublicPage({ storeId: this.personInfo.bulidingId }).then(res => {

            this.communityList.find(p => p.id == this.personInfo.community).children.find(p => p.id == this.personInfo.liveId).children.find(p => p.id == this.personInfo.bulidingId).children = res.data
          })
        })



      })


    }, onChange(data) {


      switch (data.tabIndex) {
        case 0:
          Api.getOrgLiveList({ communityId: data.value }).then(res => {

            res.data.forEach(element => {
              element["children"] = []
            });
            this.communityList.find(p => p.id == data.value).children = res.data


          })
          break;
        case 1:

          Api.getOrgStoreList({ liveId: data.value }).then(res => {

            res.data.forEach(element => {
              element["children"] = []
            });
            this.communityList.find(p => p.id == data.selectedOptions[0].id).children.find(p => p.id == data.value).children = res.data
          })

          break;

        case 2:

          Api.getOrgPublicPage({ storeId: data.selectedOptions[2].id }).then(res => {

            this.communityList.find(p => p.id == data.selectedOptions[0].id).children.find(p => p.id == data.selectedOptions[1].id).children.find(p => p.id == data.selectedOptions[2].id).children = res.data
          })


          break;



      }


    },

    onFinish(data) {
      this.show = false;

      this.fieldValue = data.selectedOptions.map((option) => option.name).join('/');
      this.personInfo.community = data.selectedOptions[0].id
      this.personInfo.gridId = data.selectedOptions[1].gridId
      this.personInfo.liveId = data.selectedOptions[1].id
      this.personInfo.bulidingId = data.selectedOptions[2].id
      this.personInfo.houseId = data.value


    },

    submit() {
      Api.PersonCheck(this.personInfo).then(res => {
        if (res.code == 0) {

          Notify({ type: 'success', message: '核查成功!' });
          this.router.push("allList") 

        } else {

          Notify({ type: 'danger', message: res.msg });

        }

      })
    }



  }

}
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>