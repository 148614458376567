<template>
  <div class="about">
    <div
      style="
        font-size: 14px;
        color: #999;
        background: #f7f8fa;
        text-align: left;
        padding-left: 20px;
      "
    >
      统计截至 <font style="color: red">{{ timeFormat(new Date()) }}</font>
    </div>
  </div>
  <van-row gutter="10" class="reportRow">
    <van-col span="8">
      <div class="reportCol">
        <van-row class="r1">较昨日+6</van-row>
        <van-row class="r2">12</van-row>
        <van-row class="r3">新增重点区域来(返)肥</van-row>
      </div>
    </van-col>
    <van-col span="8">
      <div class="reportCol">
        <van-row class="r1">较昨日-6</van-row>
        <van-row class="r2">21</van-row>
        <van-row class="r3">在管重点区域来(返)肥</van-row>
      </div>
    </van-col>
    <van-col span="8">
      <div class="reportCol">
        <van-row class="r1">中高风险地区10人</van-row>
        <van-row class="r2">700</van-row>
        <van-row class="r3">带星人员</van-row>
      </div>
    </van-col>
  </van-row>
  <van-row gutter="10" class="reportRow">
    <van-col span="8">
      <div class="reportCol">
        <van-row class="r1"></van-row>
        <van-row class="r2">22</van-row>
        <van-row class="r3">酒店隔离</van-row>
      </div>
    </van-col>
    <van-col span="8">
      <div class="reportCol">
        <van-row  class="r1"></van-row>
        <van-row  class="r2">38</van-row>
        <van-row  class="r3">居家隔离</van-row>
      </div>
    </van-col>
    <van-col span="8">
      <div class="reportCol">
      <van-row class="r1"></van-row>
        <van-row class="r2">398</van-row>
        <van-row  class="r3">健康监测</van-row>
      </div>
    </van-col>
  </van-row> 
  <van-tabs v-model:active="active">
    <van-tab title="所有人员">
      <div class="dataList">
        <router-link
          :to="{ name: '人员详细信息', query: { id: index } }"
          v-for="(item, index) in dataForm.data.list"
          :key="index"
        >
          <div class="dataItem">
            <van-row class="r1">
              <span class="title">张华满</span>
              <van-tag class="leftTag" type="primary">居家隔离</van-tag>
              <van-tag class="rightTag" type="warning">逾期5天</van-tag>
            </van-row>
            <van-row class="r2">
              <van-col span="10">
                <div>身份证号</div>
                <div>3408965699269210211</div>
              </van-col>
              <van-col class="colMiddle" span="6">
                <div>联系电话</div>
                <div>15126532652</div>
              </van-col>
              <van-col span="8">
                <div>上次填报时间</div>
                <div>2022-4-13 11:58</div>
              </van-col>
            </van-row>
          </div>
        </router-link>
        <van-pagination
          v-model="dataForm.currentPage"
          :total-items="dataForm.data.total"
          :items-per-page="10"
          :show-page-size="5"
          @change="getDateList" />
      </div>
    </van-tab>
    <van-tab title="地区统计">
      <van-row   class="ListHead"  gutter="20">
        <van-col span="9"> 返回前所在地区 </van-col>
        <van-col  span="5"> 居家隔离 </van-col>
        <van-col  span="5"> 集中隔离 </van-col>
        <van-col  span="5"> 健康监测 </van-col>
      </van-row>
      <van-row  class="ListRow" gutter="20">
        <van-col  span="9"> 合肥 </van-col>
        <van-col  span="5"> 4 </van-col>
        <van-col  span="5"> 6 </van-col>
        <van-col  span="5"> 2 </van-col>
      </van-row>
      <van-row  class="ListRow" gutter="20">
        <van-col  span="9"> 上海 </van-col>
        <van-col  span="5"> 14 </van-col>
        <van-col  span="5"> 26 </van-col>
        <van-col span="5"> 200 </van-col>
      </van-row>
    </van-tab>
  </van-tabs>
</template>

<script>
// @ is an alias to /src 
import { reactive } from 'vue';
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: 'Home',
  components: {
  },
  setup() {
    const dataForm = reactive({
      options1: [
        { text: '人员状态', value: 0 },
        { text: '待核查', value: 1 },
        { text: '待审核', value: 2 },
        { text: '待转运', value: 3 },
        { text: '集中隔离中', value: 4 },
        { text: '居家隔离中', value: 5 },
        { text: '健康监测中', value: 6 },
        { text: '解除管控', value: 7 }],
      options2: [
        { text: '人员类型', value: 0 },
        { text: '密接', value: 1 },
        { text: '次密接', value: 2 },
        { text: '阳性物品暴露人员', value: 3 },
        { text: '高风险区域来(返)肥人员', value: 4 },
        { text: '红黄码人员', value: 5 },
        { text: '其他情况', value: 6 },
      ],
      searchKey: '',
      searchState: 0,
      searchType: 0,
      currentPage: 1,
      data: {
        total: 100,
        list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
      }
    });
    let getDateList = () => {
      //posttest({"username":"admin","password":"admin","uuid":"e50547e4-1135-4bc6-8030-f9c54032a847","captcha":"www"})

      //dataForm.data=  getPersoninfoDataList({limit:10,listType:1,page:dataForm.currentPage,personName:dataForm.searchKey})
    }
    const onSearch = (val) => {
      dataForm.searchKey = val
      getDateList()
    };
    const timeFormat = function (time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + '年' + month + '月' + day + '日' + " " + hour + "时" + min + '分'
    }
    return { dataForm, getDateList, onSearch, timeFormat }
  },
  mounted() {
    this.$emit('changeTitle', "全部人员列表",)
    this.getDateList();
  },

}
</script>
<style scoped>
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
.reportRow {
  width: 100%;
  text-align: center;
}
.reportRow .reportCol {
  width:100%;
  margin: 5px;
  background: #f7f5f5;
  height: 80px;
 align-items: center;
}

.reportRow .reportCol .r1 {
  width: 100%;
  font-size: 12px;
  height: 30%;
  justify-content: center;
  align-items: flex-end;
}
.reportRow .reportCol .r2 {
  width: 100%;
  font-size:24px;
   height: 40%;
   font-weight: bold;
 justify-content: center;
    align-items: center;
     
}
.reportRow .reportCol .r3 {
  width: 100%;
  font-size: 12px;
   height: 30%;
  justify-content: center;
   align-items: flex-start;
}
.ListRow{
margin-bottom: 10px;
padding: 5px;
    background:#f7f5f5 ;
}
.ListHead{ 
    margin-top:20px ;
    margin-bottom: 10px;
padding: 5px;
    background:#f7f5f5 ;
}

</style>
