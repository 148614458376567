<template>
<van-pull-refresh
  v-model="isLoading"
  success-text="刷新成功"
  @refresh="getDateList"
>
  <div class="about">
    <van-dropdown-menu>
      <van-dropdown-item
        v-model="dataForm.searchState"
        :options="dataForm.options1"
      />
      <van-dropdown-item
        v-model="dataForm.searchType"
        :options="dataForm.options2"
      />
    </van-dropdown-menu>
    <van-search
      v-model="dataForm.searchKey"
      @search="onSearch"
      placeholder="请输入姓名"
    />
    <div
      style="
        font-size: 14px;
        color: #999;
        background: #f7f8fa;
        text-align: left;
        padding-left: 20px;
      "
    >
      为你筛选出<label style="color: red" v-html="dataForm.data.total"></label>条结果
    </div>
  </div>

  <div class="dataList">
    <router-link
      :to="{ name: '人员详细信息', query: { id: item.id } }"
      v-for="(item, index) in dataForm.data.list"
      :key="index"
    >
      <div class="dataItem">
        <van-row class="r1">
          <span class="title">{{item.personName}}</span>
          <van-tag class="leftTag" v-if=" item.voucherType!=null" type="primary">{{  dataForm.options1[item.voucherType].text}}</van-tag>
          <van-tag class="rightTag" type="warning">{{item.lateDay}}</van-tag>
        </van-row>
        <van-row class="r2">
          <van-col span="10">
            <div>身份证号</div>
            <div>{{item.idCard}}</div>
          </van-col>
          <van-col class="colMiddle" span="6">
            <div>联系电话</div>
            <div>{{item.phone}}</div>
          </van-col>
          <van-col span="8">
            <div>返肥时间</div>
            <div>{{item.returnDate}}</div>
          </van-col>
        </van-row>
      </div>
    </router-link>
    <van-pagination
      v-model="dataForm.currentPage"
      :total-items="dataForm.data.total"
      :items-per-page="10"
      :show-page-size="5"
      @change="getDateList"
    />
  </div>
   </van-pull-refresh>
</template>

<script>
// @ is an alias to /src 
import { reactive,ref } from 'vue';
import Api from '../util/App';
//import { getPersoninfoDataList,posttest } from '../util/Api'
export default {
  name: 'Home',
  components: {
  },
  setup() {
    const dataForm = reactive({
      options1: [
        { text: '人员状态', value: 0 },
        { text: '待核查', value: 1 },
        { text: '待审核', value: 2 },
        { text: '待转运', value: 3 },
        { text: '集中隔离中', value: 4 },
        { text: '居家隔离中', value: 5 },
        { text: '健康监测中', value: 6 },
        { text: '解除管控', value: 7 }],
      options2: [
        { text: '人员类型', value: 0 },
        { text: '密接', value: 1 },
        { text: '次密接', value: 2 },
        { text: '阳性物品暴露人员', value: 3 },
        { text: '高风险区域来(返)肥人员', value: 4 },
        { text: '红黄码人员', value: 5 },
        { text: '其他情况', value: 6 },
      ],
      searchKey: '',
      searchState: 0,
      searchType: 0,
      currentPage: 1,
      data: { 
        total: 0,
         "list": [
         //  { "id": "1514854093102907394", "personId": "1514796159924867073", "controlId": null, "voucherDate": "2022-04-15", "voucherImg": null, "temperatureImg": null, "voucherType": "3", "remark": null, "creator": "1067246875800000001", "createDate": "2022-04-15 14:32:15", "updater": null, "updateDate": null, "isDelete": "0", "personName": "", "idCard": "", "phone": "", "controlStep": null, "controlStatus": null, "personInfo": null, "lateDay": "逾期4天", "releaseCertificate": null }
           
           ]
          }
    });
    let getDateList = () => {
 
       Api.getPersonList({ limit: 10, listType: 2, page: dataForm.currentPage, personName: dataForm.searchKey }).then(res => { 
         dataForm.data =res.data
             this.isLoading=false
       }) 
  
    }
    const onSearch = (val) => {
      dataForm.searchKey = val
      getDateList()
    };
     const isLoading=ref(false)
    return { dataForm, getDateList, onSearch,isLoading }
  },
  mounted() {
    this.$emit('changeTitle', "全部人员列表",)
    this.getDateList();
  },

}
</script>
<style scoped>
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
.van-pull-refresh >>> .van-pull-refresh__track {
min-height: 90vh;

}
</style>
