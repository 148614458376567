<template>
<van-pull-refresh style="min-height:100vh;"
  v-model="isLoading"
  success-text="刷新成功"
  @refresh="getDateList"
>
 
    <div v-if="dataForm.status=='NotTransshipped'" title="待转运">
      <div class="about">
        <van-search
          v-model="dataForm.tabs2.searchKey"
          @search="onTabs2Search"
          placeholder="请输入搜索关键词"
        />
        <div
          style="
            font-size: 14px;
            color: #999;
            background: #f7f8fa;
            text-align: left;
            padding-left: 20px;
          "
        >
          为你筛选出<font
            style="color: red"
            v-html="dataForm.tabs2.data.total"
          ></font
          >条结果
        </div>
      </div>
      <div class="dataList">
        <div
          class="dataItem"
         
          v-for="(item, index) in dataForm.tabs2.data.list"
          :key="index"
        >
          <router-link :to="{ name: '转运单详情', query: { id: item.id } }">
            <van-row class="r1">
              <span class="title">{{ item.personName }}</span>
              <van-tag class="rightTag" type="warning">{{
                this.getDictLabel("TransportType", item.carType)
              }}</van-tag>
            </van-row>
            <van-row class="r2">
              <van-col span="10">
                <div class="itemTitle">身份证号</div>
                <div class="itemValue">{{ item.idCard }}</div>
              </van-col>
              <van-col class="colMiddle" span="6">
                <div class="itemTitle">联系电话</div>
                <div class="itemValue">{{ item.phone }}</div>
              </van-col>
              <van-col span="8">
                <div class="itemTitle">预计转运开始时间</div>
                <div class="itemValue">{{ item.transportStartTime }}</div>
              </van-col>
            </van-row>
            <!-- <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">出发地</div>
              </van-col>
              <van-col span="16">
                <div>{{ item.startPlace }}</div>
              </van-col>
            </van-row> -->
            <van-row class="r2">
              <van-col span="8">
                <div class="itemTitle">目的地</div>
              </van-col>
              <van-col span="16">
                <div>{{ item.destination }}</div>
              </van-col>
            </van-row>
          </router-link>
        </div>
        <van-pagination
          v-if="dataForm.tabs2.data.total > 0"
          v-model="dataForm.tabs2.currentPage"
          :total-items="dataForm.tabs2.data.total"
          :items-per-page="10"
          :show-page-size="5"
          @change="getTab2DateList"
        /></div
    ></div>
 
 
  </van-pull-refresh>
</template>

<script> 
// @ is an alias to /src 
import { reactive,ref } from 'vue';
import Api from '../util/Api'
import { useRouter } from 'vue-router'
import { Dialog, Notify } from 'vant'
export default {
  name: 'Home',
  components: {

  },

  setup() {
    const dataForm = reactive({
      status:'NotTransshipped',
      tabs2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //   {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      },

      tabs2_2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //   {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      }, tabs2_3: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
            //{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
          ]
        }
      },
    });

    const dicPersonTypeLabel = ["其他情况", "红黄码人员", "中高风险地区返回", "市外返回", "省外返回", "境外人员", "阳性物品暴露人员", "次密接", "密接"]

    const dicPersonType = ['Other', 'RedAndYellow', 'HighRisk', "OutsideCity", "OutsideProvince", "Abroad", 'PositiveExposure', 'SecondTouch', 'Touch']

    const dicPersonTypeList = [{ value: 'Other', label: '其他情况' }, { value: 'RedAndYellow', label: '红黄码人员' }, { value: 'HighRisk', label: '中高风险地区返回' }, { value: 'OutsideCity', label: '市外返回' }, { value: 'OutsideProvince', label: '省外返回' }, { value: 'Abroad', label: '境外人员' }, { value: 'PositiveExposure', label: '阳性物品暴露人员' }, { value: 'SecondTouch', label: '次密接' }, { value: 'Touch', label: '密接' },]

    const SensorDataTypeArray = ["已关门", "故障", "已开门"]
    const SensorDeviceStateArray = ["正常", "故障", "报警", "离线"]
    const isLoading=ref(false)
    const router = useRouter()
    return { dataForm,isLoading,router, dicPersonTypeLabel, dicPersonType, dicPersonTypeList, SensorDataTypeArray, SensorDeviceStateArray }

  },
  mounted() {
    this.$emit('changeTitle', "待核查人员信息")
  let status =  this.router.currentRoute.value.query.status;

  if(status!=null&&status!='')
  {
          this.dataForm.status=status;

  }

    this.getTab2DateList();
  

  },
  activated() {
    this.getTab2DateList();
  
  },

  methods:
  {
  getDateList(){

      this.getTab2DateList();
 

          let that =this; 
setTimeout(that.isLoading=false,1000)
  },
    onTabs2Search(val) {
      this.dataForm.tabs2.searchKey = val
      this.getTab2DateList()
    },
    getTab2DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2.currentPage, personName: this.dataForm.tabs2.searchKey, status: "NotTransshipped" }).then(res => {
        this.dataForm.tabs2.data = res.data
      })
    },
    onTabs2_2Search(val) {
      this.dataForm.tabs2_2.searchKey = val
      this.getTab2_2DateList()
    },
    getTab2_2DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2_2.currentPage, personName: this.dataForm.tabs2_2.searchKey, status: "InTransit" }).then(res => {
        this.dataForm.tabs2_2.data = res.data
      })
    },

    onTabs2_3Search(val) {
      this.dataForm.tabs2_2.searchKey = val
      this.getTab2_3DateList()
    }
    ,
    getTab2_3DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2_3.currentPage, personName: this.dataForm.tabs2_3.searchKey, status: "Transshipped" }).then(res => {
        this.dataForm.tabs2_3.data = res.data
      })
    },
    getDictLabel(type, value) {
      try {
        return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      } catch {
        return ''

      }
    },

  }
}
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
 
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
min-height: 90vh;

}
</style>