<template>
  <div class="about">
    <van-cell-group title="隔离人员信息">
      <van-cell title="姓名" :value="transport.personName" />
      <van-cell title="电话" :value="transport.phone" />
      <van-cell title="身份证号" :value="transport.idCard" />
    </van-cell-group>

    <van-cell-group title="转运信息">
      <!-- <van-cell title="转运起始地点" :value="transport.startPlace" /> -->
      <van-cell title="转运目的地" :value="transport.destination" />
      <!-- <van-cell
        title="预计转运开始时间"
        :value="transport.transportStartTime"
      />
      <van-cell title="预计转运到达时间" :value="transport.transportEndTime" /> -->
      <van-cell title="转运开始时间"  v-if="transport.status != 'NotTransshipped'"  :value="transport.transportTime" />
      <van-cell title="转运到达时间"  v-if="transport.status != 'NotTransshipped'"   :value="transport.arriveTime" />
    </van-cell-group>
    <van-cell-group title="司机信息" >
      <van-cell title="司机姓名" :value="transport.driverName" />
      <van-cell title="联系电话" :value="transport.driverPhone" />
    </van-cell-group>

    <van-cell-group title="转运凭证填报"  v-if="transport.status != 'NotTransshipped'"  >
      <van-field
        v-model="transport.remark"
        name="备注"
        label="备注"
        placeholder="备注"
      />
      <van-cell title="转运单凭证">
        <template #value>
          <van-image
            width="100"
            height="100"
            @click="
              ImagePreview({
                images: [transport.orderImg],
                closeable: true,
              })
            "
            :src="transport.orderImg"
          />
        </template>
      </van-cell>

      <van-cell title="转运凭证">
        <template #value>
          <van-image
            width="100"
            height="100"
            @click="
              ImagePreview({
                images: [transport.transportImg],
                closeable: true,
              })
            "
            :src="transport.transportImg"
          />
        </template>
      </van-cell>
    </van-cell-group>
  </div>
   
</template>

<script>
import { ref } from '@vue/reactivity'
// @ is an alias to /src 
import { useRouter } from 'vue-router'
import { ImagePreview, Notify, Dialog,Toast } from 'vant';
import Api, { getPersoninfo } from '../util/Api'
export default {
  name: 'Home',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {

    const transport = ref({
      "id": "",
      "controlId": "",
      "personId": "",
      "userId": null,
      "driverId": null,
      "driverName": null,
      "status": "",
      "driverPhone": null,
      "startPlace": null,
      "destination": null,
      "transportTime": null,
      "transportStartTime": null,
      "transportEndTime": null,
      "arriveTime": null,
      "transportImg": null,
      "orderImg": null,
      "transportType": "",
      "carType": "",
      "remark": "",
      "creator": "",
      "createDate": "",
      "updater": null,
      "updateDate": null,
      "isDelete": "0",
      "personName": null
    })
    const result = ref('');
    const dicPersonTypeLabel = ["其他情况", "红黄码人员", "高风险地区来（返）肥人员", "阳性物品暴露人员", "密接、次密接"]
    const dicPersonType = ['OtherCases', 'CodePersonnel', 'Highrisk', 'PositiveArticles', 'Touch']
    const dicPersonTypeList = [{ value: 'OtherCases', label: '其他情况' }, { value: 'CodePersonnel', label: '红黄码人员' }, { value: 'Highrisk', label: '高风险地区来（返）肥人员' }, { value: 'PositiveArticles', label: '阳性物品暴露人员' }, { value: 'Touch', label: '密接、次密接' },]
    const showPicker = ref(false);
    const showPicker1 = ref(false);
    const orderImgList = ref([]);
    const transportImgList = ref([]);
    const DatetimePickerField = ref('');
    const router = useRouter()
    const checked = 1 
  const  role =ref('')
    return { router, transport, dicPersonType, dicPersonTypeLabel, ImagePreview, showPicker, dicPersonTypeList, result, showPicker1, checked, orderImgList, transportImgList, DatetimePickerField }
  },
  mounted() {
    this.$emit('changeTitle', "居家隔离人员详情",)
     this.role=   window.localStorage.getItem('role')
    let id = this.router.currentRoute.value.query.id;
    this.initdata(id)
  }, 
    activated() { 
          this.role=   window.localStorage.getItem('role')
         let id = this.router.currentRoute.value.query.id;
         this.initdata(id)
    }, 
  methods:
  {
    initdata(id) {
      Api.gettsDetail(id).then((res) => {
        this.transport = res.data

      });
    },
    timeFormat(time) { // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      let hour = time.getHours();
      let min = time.getMinutes();
      return year + '-' + this.padLeft(month, 2) + '-' + this.padLeft(day, 2) + " " + this.padLeft(hour, 2) + ":" + this.padLeft(min, 2) + ":00"
    },
    padLeft(num, n) {
      let y = '00000000000000000000000000000' + num; //爱几个0就几个，自己够用就行  
      return y.substr(y.length - n);
    },
    StartTransport() {
      Dialog.confirm({
        title: '系统消息',
        message:
          '确认开始转运？',
      })
        .then(() => {
          // on confirm 

  const toast = Toast.loading({
  duration: 0,
  forbidClick: true,
  message: '数据传输中',
});

          Api.gettsconfirm(this.transport.id).then((res) => {
            toast.clear()
            if (res.code == 0) {
              Notify({ type: 'success', message: '提交开始转运成功!' });
              this.router.back()
            } else {
              Notify({ type: 'danger', message: res.msg });
            }
          })


        })
        .catch(() => {
          // on cancel

        });


    }
  }

}
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>