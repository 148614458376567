import { service } from './request'
 
export default{
// post请求
   postDataList:(params) => service.post('/xxxx', params),
 
// get请求
    getDataList:(params) => service.get('/xxxx', {params}),
 
//https://cx.ringzle.com/cxcanteen-api/login
// post请求
    posttest:(params) => service.post('/app/login', params),
 



/**
 * @description:  人口库人员详细信息
 * @params {*}   idCard 身份证号
 * @return {*}
 * */
 getOrgPersonBaseInfo:(params)=>service.get('/app/org/baseInfo/'+params,{}),
 

/**
 * @description:  人口库获取社区信息
 * @params {*}  
 * @return {*}
 * */
 getOrgCommunityList:(params)=>service.get('/app/org/communityList',{params:params}),
  

/**
 * @description: 人口库获取小区信息
 * @params {*}   communityId 社区id  
 * @params {*}   villageId 小区id
 * @return {*}
 * */
 getOrgLiveList:(params)=>service.get('/app/org/liveList',{params:params}),
  
  /**
 * @description: 人口库获取楼栋信息
 * @params {*}   liveId 小区id
 * @return {*}
 * */
   getOrgStoreList:(params)=>service.get('/app/org/storeList',{params:params}),



 /**
 * @description: 人口库获取房屋信息
 * @params {*}   limit 显示条数
 * @params {*}   liveId 小区id
 * @params {*}   page  页
 *  @params {*}  storeId 楼栋id 
 * @return {*}
 * */
  getOrgPublicPage:(params)=>service.get('/app/org/publicPage',{params:params}),
 
/**
 * @description: 获取所有人员信息
 * @params {*}   limit  page listType personName
 * @return {*}
 * */
  getPersonList:(params)=>service.get('/app/msgpage',{params:params}),
 
/**
 * @description: 管控人员详细信息
 * @params {*}   personId 管控人员ID
 * @return {*}
 * */
    getPersoninfo:(params) => service.get('/app/msgdetail/'+params, {}),


 /**
 * @description:  人员信息上报
 * @params {*}    person Json add
 * @return {*}
 * */
    addPersonInfo:(params)=>service.post('/app/msgadd',params),


 

   /**
 * @description:  人员信息调整 （改变管控状态、隔离时间）
 * @params {*}    person Json  
 * @return {*}
 * */
   controlUpdate:(params)=>service.post('/app/msgupdate',params),


      /**
 * @description:  隔离人员核查  
 * @params {*}    person Json  
 * @return {*}
 * */
   PersonCheck:(params)=>service.post('/app/check',params),

   /**
    * @description:  行政区域
    * @params {*}    person Json  
    * @return {*}
    * */
    getRegionList:(params)=>service.get('/app/tree',params),
   
 

    /**
    * @description:  转运分页
   * @params {*}   limit 显示条数
   * @params {*}   personName 人员姓名
   * @params {*}   page  页
   *  @params {*}  status  转运状态:未转运NotTransshipped,转运中InTransit,Transshipped已转运
    * @return {*}
    * */
      gettspage:(params)=>service.get('/app/tspage',{params:params}),

        /**
    * @description:  转运详情
    * @params {*}   id  
    * @return {*}
    * */
      gettsDetail:(params) => service.get('/app/tsdetail/'+params, {}),

   /**
    * @description:  转运修改
    * @params {*}    json 
    * @return {*}
    **/
      gettsUpdate:(params) => service.post("/app/tsupdate", params),

 
    /**
      * @description:  填报任务分页
      * @params {*}   limit 显示条数
      * @params {*}   personName 人员姓名
      * @params {*}   page  页
      *  @params {*}  dataType  数据:1当日信息；2全部
      * @return {*}
    * */
      getvhpage:(params)=>service.get('/app/vhpage',{params:params}),

    /**
      * @description:  填报任务详情
      * @params {*}   Id   
      * @return {*}
    * */
     getvhDetail:(params)=>service.get('/app/vhdetail/'+params, {}),


  /**
    * @description:  网格员任务填报
    * @params {*}    json 
    * @return {*}
    **/
   getSubmit:(params) => service.post("/app/submit", params),

 

   /**
      * @description:  数据字典数据
      * @return {*}
    * */
     getDictList:(params)=>service.get('/app/all',{}),

     

}