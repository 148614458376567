<template>
<div class="about">
   <van-tabs title="基本信息" v-model:active="active"   sticky>
  <van-tab title="人员信息">  
      <van-cell title="姓名" :value="personInfo.personName" />
      <van-cell title="性别" :value="personInfo.sex" />
      <van-cell title="年龄" :value="GetAge(personInfo.idCard)" />
      <van-cell title="身份证号" :value="personInfo.idCard" />
      <van-cell title="联系电话" :value="personInfo.phone" />
      <van-cell title="工作单位" :value="personInfo.workUnit" />
      <van-cell title="现居住地"  :value="personInfo.address" />
      <van-cell title="所属社区"  :value="DataInfo.Community" />
      <van-cell title="所属网格"  :value="DataInfo.Live" />
      <van-cell title="居住楼栋"  :value="DataInfo.Store" />
      <van-cell title="居住房屋号" :value="DataInfo.House" /> 
      <van-cell title="户籍地址" :value="personInfo.permanentAddress" />
      <van-cell title="基础疾病" :value="personInfo.disease" />
      <van-cell title="数据来源" :value="  this.getDictLabel('DataSourceType',personInfo.dataSoucre)" />
 
 
    <!-- 密接次密接 需填写病例 、密接姓名、m末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
    <!-- 阳性物品暴露人员 需填写 末次接触日期 、接触方式、返回合肥时间、返回前所在地区、安康码、行程码-->
    <!-- 高风险地区来（返）肥人员 需填写  返回合肥时间、返回前所在地区、安康码、行程码-->
    <!-- 红黄码人员 需填写 安康码、行程码-->
    <!-- 其他情况 需填写  其他说明、安康码、行程码-->
 
 
          <van-cell
       
        title="人员类型"
        :value="dicPersonTypeLabel[dicPersonType.indexOf(personInfo.personType)]"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 4"
        title="密接病例"
        :value="personInfo.touchCase"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 4"
        title="接触方式"
        :value="personInfo.releaseMode"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 3"
        title="末次密接日期"
        :value="personInfo.lastContactDate"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
        title="返回合肥时间"
        :value="personInfo.returnDate"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
        title="返回前所在地区"
        :value="DataInfo.returnRegion"
      />
  
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
        title="交通工具"
        :value="personInfo.vehicle"
      />
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) >= 2"
        title="航班车次号"
        :value="personInfo.flightNumber"
      />

      <van-cell
        title="安康码"
        v-if="dicPersonType.indexOf(personInfo.personType) >= 1"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.ankangCode"
            @click="
              ImagePreview({
                images: [personInfo.ankangCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        title="行程码"
        v-if="dicPersonType.indexOf(personInfo.personType) >= 1"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.tripCode"
            @click="
              ImagePreview({
                images: [personInfo.tripCode],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell
        v-if="dicPersonType.indexOf(personInfo.personType) == 0"
        title="其他说明"
        :label="personInfo.remark"
      />
  </van-tab> 
   <van-tab title="管控措施">
 
      <van-cell title="管控措施" :value="  this.getDictLabel('controlStep',personInfo.controlMsg.controlStep) " />
      <van-cell title="管控状态" :value="  this.getDictLabel('ControlStatus', personInfo.controlMsg.controlStatus) " />
      <van-cell title="隔离城市" :value="personInfo.isolatedCity" />
      <van-cell title="隔离酒店" :value="personInfo.isolatedHotel" />
      <van-cell title="解除隔离日期" :value="personInfo.controlMsg.relieveCentralizeDate" />
      <van-cell title="隔离填报周期" :value=" this.getDictLabel('ReportingCycle', personInfo.controlMsg.centralizeCycle)" />
      <van-cell
        title="解除健康监测日期"
        :value="personInfo.controlMsg.relieveHealthDate"
      />
      <van-cell title="健康监测填报周期" :value=" this.getDictLabel('ReportingCycle', personInfo.controlMsg.healthCycle)" />
      <van-cell title="核酸检测照片">
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.nucleinImg"
            @click="
              ImagePreview({
                images: [personInfo.nucleinImg],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      <van-cell title="解除隔离证明">
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="personInfo.isolatedProve"
            @click="
              ImagePreview({
                images: [personInfo.isolatedProve],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
     </van-tab> 
     <van-tab title="每日填报">
        <van-cell-group title="隔离期期核酸监测凭证">
      <div class="imageListRoll box">
        <div
          class="imgHozItem"
          title=""
          v-for="(item, index) in personInfo.healthVouchers"
          :key="index"
        >
          <div class="imgHozItemName">{{ item.temperatureImg }}℃</div>
          <van-image
            width="100"
            height="100"
            @click="
              ImagePreview({
                images: [item.voucherImg],
                closeable: true,
              })
            "
            :src="item.voucherImg"
          />
          <div class="imgHozItemName">{{ item.voucherDate }}</div>
        </div>
      </div>
   </van-cell-group>

 
      <div class="imageListRoll box">
        <div
          class="imgHozItem"
          title=""
          v-for="(item, index) in personInfo.homeVouchers"
          :key="index"
        >
          <div class="imgHozItemName">{{ item.temperatureImg }}℃</div>
          <van-image
            width="100"
            height="100"
            @click="
              ImagePreview({
                images: [item.releaseCertificate],
                closeable: true,
              })
            "
            :src="item.releaseCertificate"
          />
          <div class="imgHozItemName">{{ item.voucherDate }}</div>
        </div>
      </div>
 
 </van-tab> 
     <van-tab title="转运记录">
      <van-cell-group  v-for="(item,index) in  personInfo.transportInfoList " :title="'记录'+(index+1)" :key="index">
          <van-cell title="转运类型" :value=" this.getDictLabel('TransportType',item.carType)  " />
        <van-cell title="转运状态" :value=" this.getDictLabel('TransportStatus',item.status)  " />
        <van-cell title="转运方式" :value="item.transportType==1?'自运':'他运'" />
        <!-- <van-cell title="出发地点" :value="item.startPlace" /> -->
        <van-cell title="目的地点" :value="item.destination" />
        <van-cell title="司机姓名" :value="item.driverName" />
        <van-cell title="司机电话" :value="item.driverPhone" />
         <van-cell title="备注" :value="item.remark" />
        
         <van-cell
        title="转运凭证"
       
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="item.transportImg"
            @click="
              ImagePreview({
                images: [item.transportImg],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
        <van-cell
        title="转运单凭证"
      >
        <template #value>
          <van-image
            width="100"
            height="100"
            :src="item.orderImg"
            @click="
              ImagePreview({
                images: [item.orderImg],
                closeable: true,
              })
            "
          />
        </template>
      </van-cell>
      </van-cell-group>
      
     </van-tab>
     <van-tab title="门磁记录">
     </van-tab>

    <van-image-preview />
   </van-tabs>
     </div>
</template>

<script>
import { ref } from '@vue/reactivity'
// @ is an alias to /src 
import Api from '../util/App';
import { useRouter } from 'vue-router'
import { ImagePreview } from 'vant';
export default {
  name: 'Home',
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  setup() {
    const personInfo = ref({ "id": "", "personName": "", "idCard": "", "phone": "", "sex": "", "age": 0, "releaseMode": "", "workUnit": "", "community": "", "grid": "", "bulidingId": "", "houseId": "", "houseCode": "A15", "address": "合肥市", "permanentAddress": null, "status": "2", "disease": "高血压", "personType": "PositiveExposure", "touchCase": "", "lastContactDate": "2022-04-11", "returnRegion": "", "returnDate": "2022-04-20", "ankangCode": "", "tripCode": "", "gridId": null, "dataSoucre": "Gridreport", "remark": "", "isolatedCity": "合肥市", "isolatedHotel": "如家酒店", "nucleinImg": "", "isolatedProve": "", "vehicle": "地铁", "flightNumber": "B6A46589", "creator": "1067246875800000001", "createDate": "2022-04-19 18:42:39", "updater": null, "updateDate": null, "isDelete": "0", "controlStep": null, "controlStatus": null, "relieveIsolationDate": null, "isolationCycle": null, "relieveHealthDate": null, "healthCycle": null, "controlMsg": null, "centralizeVouchers": null, "homeVouchers": null, "healthVouchers": null })
   const dicPersonTypeLabel = ["其他情况", "红黄码人员", "中高风险地区返回", "市外返回", "省外返回", "境外人员", "阳性物品暴露人员", "次密接", "密接"]

    const dicPersonType = ['Other', 'RedAndYellow', 'HighRisk', "OutsideCity", "OutsideProvince", "Abroad", 'PositiveExposure', 'SecondTouch', 'Touch']

    const dicPersonTypeList = [{ value: 'Other', label: '其他情况' }, { value: 'RedAndYellow', label: '红黄码人员' }, { value: 'HighRisk', label: '中高风险地区返回' },{ value: 'OutsideCity', label: '市外返回' },{ value: 'OutsideProvince', label: '省外返回' },{ value: 'Abroad', label: '境外人员' }, { value: 'PositiveExposure', label: '阳性物品暴露人员' }, { value: 'SecondTouch', label: '次密接' }, { value: 'Touch', label: '密接' },]
   const router = useRouter()
    const GetAge = function (identityCard) {
      let len = (identityCard + "").length;
      let strBirthday = "";
      if (len == 18) {
        //处理18位的身份证号码从号码中得到生日和性别代码
        strBirthday = identityCard.substr(6, 4) + "/" + identityCard.substr(10, 2) + "/" + identityCard.substr(12, 2);
      }
      if (len == 15) {
        let birthdayValue = "";
        birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
        if (parseInt(birthdayValue) < 10) {
          strBirthday = "20" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        } else {
          strBirthday = "19" + identityCard.substr(6, 2) + "/" + identityCard.substr(8, 2) + "/" + identityCard.substr(10, 2);
        }
      }
      let birthDate = new Date(strBirthday);
      let nowDateTime = new Date();
      let age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return isNaN(age) ? '' : age;
    }

    const communityList = ref([])
    const  DataInfo=ref(
      {
             returnRegion:'',
             Community:'',
             Live:'',
             Store:'',
             House:''


      })
    return { router, personInfo, dicPersonType, dicPersonTypeLabel, ImagePreview, GetAge, communityList,DataInfo }
  },
  mounted() {

    this.$emit('changeTitle', "居家隔离人员详情",)
    let id = this.router.currentRoute.value.query.id;
    this.initdata(id)
  },
  methods:
  {
    initdata(id) {
      Api.getPersoninfo(id).then(res => {
        this.personInfo = res.data
         this.getRegion();
         this.getCommunityAllList()
      })
    },
    getRegion() {
      if(this.personInfo.returnRegion=='') return ''
      Api.getRegionList().then(res => { 
        try{
        let list = res.data;
        let county = list.find(p => p.id == this.personInfo.returnRegion);
        let city = list.find(p => p.id == county.pid);
        let province = list.find(p => p.id == city.pid);
    
        this.DataInfo.returnRegion= province.name + "/" + city.name + "/" + county.name;
       } catch{
        //

       }
      })

    },
    getCommunityAllList() {

      Api.getOrgCommunityList().then(res => {
           try{
        this.communityList = res.data
        this.DataInfo.Community =this.communityList.find(p=>p.id==this.personInfo.community).name
         }catch{
           return ''
         }  
      })
      Api.getOrgLiveList({ communityId: this.personInfo.community }).then(res => {
           try{
         this.DataInfo.Live =res.data.find(p=>p.id==this.personInfo.liveId).name
          }catch{
           return ''
         }  
      })

      Api.getOrgStoreList({ liveId: this.personInfo.liveId }).then(res => {
   try{
        this.DataInfo.Store =res.data.find(p=>p.id==this.personInfo.bulidingId).name
         }catch{
           return ''
         }  
      })

      Api.getOrgPublicPage({ storeId: this.personInfo.bulidingId }).then(res => {
   try{
       this.DataInfo.House =res.data.find(p=>p.id==this.personInfo.houseId).name
         }catch{
           return ''
         }  
      })

    },
    getDictLabel(type,value){
        try{
         return this.$store.state.Dict.find(p=>p.dictType==type).dataList.find(p=>p.dictValue==value).dictLabel    
         }catch{
           return ''
         }  
    }
  }

}
</script>
<style scoped>
.about {
  text-align: left !important;
  margin-bottom: 20px;
}
.imageListRoll {
  width: 100%;
  overflow: hidden;
  overflow-x: auto;
  white-space: nowrap;
}
.imageListRoll .imgHozItem {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 5px;
  border: 1px solid #000;
}
.imageListRoll .imgHozItemName {
  width: 100px;
  height: 20px;
  position: absolute;
  bottom: 0px;
  font-size: 12px;
  color: #fff;
  background: #00000099;
  text-align: center;
  line-height: 20px;
}

.box::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.box::-webkit-scrollbar-track,
.box::-webkit-scrollbar-thumb {
  border-radius: 999px;
}
.box::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.2);
}
.box::-webkit-scrollbar-thumb {
  background-color: rgba(90, 18, 18, 0.5);
}
.box::-webkit-scrollbar-corner {
  background: transparent;
}
</style>