<template>
  <div class="home">
    <van-tabs style="margin-top: 10px" v-model:active="active" type="card">
      <van-tab title="待核查">
        <div class="about">
          <van-search
            v-model="dataForm.tabs1.searchKey"
            @search="onTabs1Search"
            placeholder="请输入搜索关键词"
          />
          <div
            style="
              font-size: 14px;
              color: #999;
              background: #f7f8fa;
              text-align: left;
              padding-left: 20px;
            "
          >
            为你筛选出<font style="color: red">{{
              dataForm.tabs1.data.total
            }}</font
            >条结果
          </div>
        </div>
        <div class="dataList">
          <router-link
            :to="{ name: '人员信息核查编辑', query: { id: item.id } }"
            v-for="(item, index) in dataForm.tabs1.data.list"
            :key="index"
          >
            <div class="dataItem">
              <van-row class="r1">
                <span class="title">{{ item.personName }}</span>
                <!-- <van-tag class="leftTag" type="primary">绿码</van-tag> -->
                <van-tag class="rightTag" type="warning">{{
                  dicPersonTypeLabel[dicPersonType.indexOf(item.personType)]
                }}</van-tag>
              </van-row>

              <van-row class="r2">
                <van-col span="10">
                  <div>身份证号</div>
                  <div>{{ item.idCard }}</div>
                </van-col>
                <van-col class="colMiddle" span="6">
                  <div>联系电话</div>
                  <div>{{ item.phone }}</div>
                </van-col>
                <van-col span="8">
                  <div>上次填报时间</div>
                  <div>{{ item.updateDate }}</div>
                </van-col>
              </van-row>
            </div>
          </router-link>
          <van-pagination
            v-model="dataForm.tabs1.currentPage"
            :total-items="dataForm.tabs1.data.total"
            :items-per-page="10"
            :show-page-size="5"
            @change="getTab1DateList"
          />
        </div>
      </van-tab>
      <van-tab title="转运执行">
        <van-tabs style="margin-top: 10px" v-model:active="activeTransport">
          <van-tab title="待转运">
            <div class="about">
              <van-search
                v-model="dataForm.tabs2.searchKey"
                @search="onTabs2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs2.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <div
                class="dataItem"
                v-for="(item, index) in dataForm.tabs2.data.list"
                :key="index"
              >
                <van-row class="r1">
                  <span class="title">{{ item.personName }}</span>
                  <van-tag class="rightTag" type="warning">{{
                  this.getDictLabel('TransportType',item.carType) 
                  }}</van-tag>
                </van-row>
                <van-row class="r2">
                  <van-col span="12">
                    <div>身份证号</div>
                    <div>{{ item.idCard }}</div>
                  </van-col>
                  <van-col span="12">
                    <div>联系电话</div>
                    <div>{{ item.phone }}</div>
                  </van-col>
                </van-row>

                <van-row>
                  <van-col span="24">
                    <van-button
                      plain
                      hairline
                      :to="{ name: '转运单填报', query: { id: item.id } }"
                      type="primary"
                      size="large"
                    >
                      指派司机
                    </van-button>
                  </van-col>
                </van-row>
              </div>

              <van-pagination
                v-model="dataForm.tabs2.currentPage"
                :total-items="dataForm.tabs2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab2DateList"
              />
            </div>
          </van-tab>
          <van-tab title="转运中">
            <div class="about">
              <van-search
                v-model="dataForm.tabs2_2.searchKey"
                @search="onTabs2_2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font
                  style="color: red"
                  v-html="dataForm.tabs2_2.data.total"
                ></font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <router-link
                :to="{ name: '转运单填报', query: { id: item.id } }"
                v-for="(item, index) in dataForm.tabs2_2.data.list"
                :key="index"
              >
                <div class="dataItem">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel('TransportType',item.carType) 
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div>身份证号</div>
                      <div>{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div>联系电话</div>
                      <div>{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div>预计转运开始时间</div>
                      <div>{{ item.transportStartTime }}</div>
                    </van-col>
                  </van-row>
                  <!-- <van-row class="r2">
                    <van-col span="8">
                      <div>出发地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.startPlace }}</div>
                    </van-col>
                  </van-row> -->
                  <van-row class="r2">
                    <van-col span="8">
                      <div>目的地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.destination }}</div>
                    </van-col>
                  </van-row>
                </div>
              </router-link>
              <van-pagination
                v-model="dataForm.tabs2_2.currentPage"
                :total-items="dataForm.tabs2_2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab2_2DateList"
              /></div
          ></van-tab>
          <van-tab title="已转运">
            <div class="about">
              <van-search
                v-model="dataForm.tabs2_3.searchKey"
                @search="onTabs2_3Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font
                  style="color: red"
                  v-html="dataForm.tabs2_3.data.total"
                ></font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <router-link
                :to="{ name: '转运单详情', query: { id: item.id } }"
                v-for="(item, index) in dataForm.tabs2_3.data.list"
                :key="index"
              >
                <div class="dataItem">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel('TransportType',item.carType) 
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div>身份证号</div>
                      <div>{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div>联系电话</div>
                      <div>{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div>预计转运开始时间</div>
                      <div>{{ item.transportStartTime }}</div>
                    </van-col>
                  </van-row>
                  <!-- <van-row class="r2">
                    <van-col span="8">
                      <div>出发地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.startPlace }}</div>
                    </van-col>
                  </van-row> -->
                  <van-row class="r2">
                    <van-col span="8">
                      <div>目的地</div>
                    </van-col>
                    <van-col span="16">
                      <div>{{ item.destination }}</div>
                    </van-col>
                  </van-row>
                </div>
              </router-link>
              <van-pagination
                v-model="dataForm.tabs2_3.currentPage"
                :total-items="dataForm.tabs2_3.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab2_3DateList"
              /></div
          ></van-tab>
        </van-tabs>
      </van-tab>
      <van-tab title="每日上报">
        <van-tabs style="margin-top: 10px" v-model:active="activeVh">
          <van-tab title="填报任务">
            <div class="about">
              <van-search
                v-model="dataForm.tabs3.searchKey"
                @search="onTabs3Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs3.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
              <router-link
                :to="{ name: '任务填报', query: { id: item.id } }"
                v-for="(item, index) in dataForm.tabs3.data.list"
                :key="index"
              >
                <div class="dataItem">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="leftTag" type="primary">
                      {{
                        this.getDictLabel("controlStatus", item.controlStatus)
                      }}
                    </van-tag>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("controlStep", item.controlStep)
                    }}</van-tag>
                  </van-row>

                <van-row class="r2">
                    <van-col span="10">
                      <div>身份证号</div>
                      <div>{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div>联系电话</div>
                      <div>{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div>上次填报时间</div>
                      <div>{{ item.voucherDate }}</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div>第一次体温</div>
                    </van-col>
      <van-col span="6">
                      <div>{{ item.firTemp }}℃</div>
                    </van-col>
                    <van-col span="6">
                      <div>第二次体温</div>
                     
                    </van-col>
                     <van-col span="6">
                 
                      <div>{{ item.secTemp }}℃</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div>核酸证明图片</div>
                    </van-col>
                       <van-col span="6">
                      <div>
                        <van-image
                         width="60"
                          height="60"
                          :src="item.voucherImg"
                          @click="
                            ImagePreview({
                              images: [item.voucherImg],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div>解除隔离证明</div>
                         </van-col>
                       <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.releaseCertificate"
                          @click="
                            ImagePreview({
                              images: [item.releaseCertificate],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </router-link>
              <van-pagination
                v-model="dataForm.tabs3.currentPage"
                :total-items="dataForm.tabs3.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab3DateList"
              /></div
          ></van-tab>
          <van-tab title="填报记录">
            <div class="about">
              <van-search
                v-model="dataForm.tabs3_2.searchKey"
                @search="onTabs3_2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs3_2.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
             
                <div class="dataItem"     v-for="(item, index) in dataForm.tabs3_2.data.list"
                :key="index">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="leftTag" type="primary">
                      {{
                        this.getDictLabel("controlStatus", item.controlStatus)
                      }}
                    </van-tag>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("controlStep", item.controlStep)
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div>身份证号</div>
                      <div>{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div>联系电话</div>
                      <div>{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div>上次填报时间</div>
                      <div>{{ item.voucherDate }}</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div>第一次体温</div>
                    </van-col>
      <van-col span="6">
                      <div>{{ item.firTemp }}℃</div>
                    </van-col>
                    <van-col span="6">
                      <div>第二次体温</div>
                     
                    </van-col>
                     <van-col span="6">
                 
                      <div>{{ item.secTemp }}℃</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div>核酸证明图片</div>
                    </van-col>
                       <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.voucherImg"
                          @click="
                            ImagePreview({
                              images: [item.voucherImg],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div>解除隔离证明</div>
                         </van-col>
                       <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.releaseCertificate"
                          @click="
                            ImagePreview({
                              images: [item.releaseCertificate],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>
                  </van-row>
                </div>
         
              <van-pagination
                v-model="dataForm.tabs3_2.currentPage"
                :total-items="dataForm.tabs3_2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab3_2DateList"
              /></div
          ></van-tab>
        </van-tabs>
      </van-tab>
      <van-tab title="门磁监控">
 <div class="dataList">
   
      <div class="dataItem"    v-for="(item, index) in dataForm.tabs4.data.list"
      :key="index">
        <van-row class="r1">
          <span class="title">{{item.personName}}</span>
          <van-tag class="leftTag" v-if=" item.voucherType!=null" type="primary">{{  SensorDataTypeArray[item.dataType]}}</van-tag>
          <van-tag class="rightTag" type="warning">{{ this.getDictLabel("controlStatus", item.controlStatus)}}</van-tag>
        </van-row>
        <van-row class="r2">
          <van-col span="10">
            <div>报警时间</div>
            <div>{{item.signTime}}</div>
          </van-col>
          <van-col class="colMiddle" span="6">
            <div>联系电话</div>
            <div>{{item.phone}}</div>
          </van-col>
          <van-col span="8">
            <div>报警事件</div>
            <div>{{item.eventName}}</div>
          </van-col>
        </van-row>
      </div>
 
    <van-pagination
      v-model="dataForm.tabs4.currentPage"
      :total-items="dataForm.tabs4.data.total"
      :items-per-page="10"
      :show-page-size="5"
      @change="getTab4DateList"
    />
  </div>
 </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// @ is an alias to /src 
import { reactive } from 'vue';
import Api from '../util/App'
export default {
  name: 'Home',
  components: {

  },

  setup() {
    const dataForm = reactive({
      tabs1: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
      tabs2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
      tabs2_2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      }, tabs2_3: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
      tabs3: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
      tabs3_2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
      tabs4: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
      tabs5: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 100,
          list: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
        }
      },
    });

    const dicPersonTypeLabel = ["其他情况", "红黄码人员", "中高风险地区返回", "市外返回", "省外返回", "境外人员", "阳性物品暴露人员", "次密接", "密接"]

    const dicPersonType = ['Other', 'RedAndYellow', 'HighRisk', "OutsideCity", "OutsideProvince", "Abroad", 'PositiveExposure', 'SecondTouch', 'Touch']

    const dicPersonTypeList = [{ value: 'Other', label: '其他情况' }, { value: 'RedAndYellow', label: '红黄码人员' }, { value: 'HighRisk', label: '中高风险地区返回' }, { value: 'OutsideCity', label: '市外返回' }, { value: 'OutsideProvince', label: '省外返回' }, { value: 'Abroad', label: '境外人员' }, { value: 'PositiveExposure', label: '阳性物品暴露人员' }, { value: 'SecondTouch', label: '次密接' }, { value: 'Touch', label: '密接' },]

    const SensorDataTypeArray = ["已关门", "故障", "已开门"]
    const SensorDeviceStateArray = ["正常", "故障", "报警", "离线"]

    return { dataForm, dicPersonTypeLabel, dicPersonType, dicPersonTypeList,SensorDataTypeArray,SensorDeviceStateArray }
  },
  mounted() {
    this.$emit('changeTitle', "待核查人员信息")
    this.getTab1DateList();
    this.getTab2DateList();
    this.getTab2_2DateList();
    this.getTab2_3DateList();
    this.getTab3DateList();
    this.getTab3_2DateList();
      this.getTab4DateList();
  },
  methods:
  {
    onTabs1Search(val) {
      this.dataForm.tabs1.searchKey = val
      this.getTab1DateList()
    },
    getTab1DateList() {
      Api.getPersonList({ limit: 10, listType: 2, page: this.dataForm.tabs1.currentPage, personName: this.dataForm.tabs1.searchKey, status: 1 }).then(res => {
        this.dataForm.tabs1.data = res.data
      })
    },
    onTabs2Search(val) {
      this.dataForm.tabs2.searchKey = val
      this.getTab2DateList()
    },
    getTab2DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2.currentPage, personName: this.dataForm.tabs2.searchKey, status: "NotTransshipped" }).then(res => {
        this.dataForm.tabs2.data = res.data
      })
    },

    onTabs2_2Search(val) {
      this.dataForm.tabs2_2.searchKey = val
      this.getTab2_2DateList()
    },
    getTab2_2DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2_2.currentPage, personName: this.dataForm.tabs2_2.searchKey, status: "InTransit" }).then(res => {
        this.dataForm.tabs2_2.data = res.data
      })
    },

    onTabs2_3Search(val) {
      this.dataForm.tabs2_2.searchKey = val
      this.getTab2_3DateList()
    }
    ,
    getTab2_3DateList() {
      Api.gettspage({ limit: 10, page: this.dataForm.tabs2_3.currentPage, personName: this.dataForm.tabs2_3.searchKey, status: "Transshipped" }).then(res => {
        this.dataForm.tabs2_3.data = res.data
      })
    },
    onTabs3Search(val) {
      this.dataForm.tabs3.searchKey = val
      this.getTab3DateList()
    },
    getTab3DateList() {
      Api.getvhpage({ limit: 10, dataType: 1, page: this.dataForm.tabs3.currentPage, personName: this.dataForm.tabs3.searchKey, }).then(res => {
        this.dataForm.tabs3.data = res.data
      })
    },
    onTabs3_2Search(val) {
      this.dataForm.tabs3_2.searchKey = val
      this.getTab3_2DateList()
    },
    getTab3_2DateList() {
      Api.getvhpage({ limit: 10, dataType: 2, page: this.dataForm.tabs3_2.currentPage, personName: this.dataForm.tabs3_2.searchKey, }).then(res => {
        this.dataForm.tabs3_2.data = res.data
      })
    },   onTabs4Search(val) {
      this.dataForm.tabs4.searchKey = val
      this.getTab4DateList()
    },
    getTab4DateList() {
       Api.getRecentList({ limit: 10, page: this.dataForm.tabs4.currentPage, keyword: this.dataForm.tabs4.searchKey, }).then(res => {
        this.dataForm.tabs4.data = res.data
      })
      
    },
    getDictLabel(type, value) {
try{
      return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      }catch{
         return ''

      }
    }

  }
}
</script>
<style scoped>
.dataItem {
  margin: 15px 10px 15px 10px;
  border: 1px solid rgb(212, 212, 212);
  padding-top: 10px;
  background: #f1e8e88f;
}
.dataItem .r1 .title {
  color: #1c1c1c;
  padding-left: 15px;
  padding-right: 15px;
}

.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
}
.dataItem .r2 .colMiddle {
  border-left: 1px solid #1c1c1c;
  border-right: 1px solid #1c1c1c;
}
</style>