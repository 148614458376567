<template>
<van-pull-refresh
  v-model="isLoading"
  success-text="刷新成功"
  @refresh="getDateList"
>
     <div class="about">
              <van-search
                v-model="dataForm.tabs3_2.searchKey"
                @search="onTabs3_2Search"
                placeholder="请输入搜索关键词"
              />
              <div
                style="
                  font-size: 14px;
                  color: #999;
                  background: #f7f8fa;
                  text-align: left;
                  padding-left: 20px;
                "
              >
                为你筛选出<font style="color: red">{{
                  dataForm.tabs3_2.data.total
                }}</font
                >条结果
              </div>
            </div>
            <div class="dataList">
             
                <div class="dataItem"     v-for="(item, index) in dataForm.tabs3_2.data.list"
                :key="index">
                  <van-row class="r1">
                    <span class="title">{{ item.personName }}</span>
                    <van-tag class="leftTag" type="primary">
                      {{
                        this.getDictLabel("controlStatus", item.controlStatus)
                      }}
                    </van-tag>
                    <van-tag class="rightTag" type="warning">{{
                      this.getDictLabel("controlStep", item.controlStep)
                    }}</van-tag>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="10">
                      <div class="itemTitle">身份证号</div>
                      <div class="itemValue">{{ item.idCard }}</div>
                    </van-col>
                    <van-col class="colMiddle" span="6">
                      <div class="itemTitle">联系电话</div>
                      <div class="itemValue">{{ item.phone }}</div>
                    </van-col>
                    <van-col span="8">
                      <div class="itemTitle">上次填报时间</div>
                      <div class="itemValue">{{ item.voucherDate }}</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div class="itemTitle">第一次体温</div>
                    </van-col>
      <van-col span="6">
                      <div>{{ item.firTemp }}℃</div>
                    </van-col>
                    <van-col span="6">
                      <div class="itemTitle">第二次体温</div>
                     
                    </van-col>
                     <van-col span="6">
                 
                      <div>{{ item.secTemp }}℃</div>
                    </van-col>
                  </van-row>
                  <van-row class="r2">
                    <van-col span="6">
                      <div class="itemTitle">核酸证明图片</div>
                    </van-col>
                       <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.voucherImg"
                          @click="
                            ImagePreview({
                              images: [item.voucherImg],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>

                    <van-col span="6">
                      <div class="itemTitle">解除隔离证明</div>
                         </van-col>
                       <van-col span="6">
                      <div>
                        <van-image
                          width="60"
                          height="60"
                          :src="item.releaseCertificate"
                          @click="
                            ImagePreview({
                              images: [item.releaseCertificate],
                              closeable: true,
                            })
                          "
                        />
                      </div>
                    </van-col>
                  </van-row>
                </div>
         
              <van-pagination
              v-if="  dataForm.tabs3_2.data.total>0"
                v-model="dataForm.tabs3_2.currentPage"
                :total-items="dataForm.tabs3_2.data.total"
                :items-per-page="10"
                :show-page-size="5"
                @change="getTab3_2DateList"
              /></div>
                </van-pull-refresh>
</template>

<script>
import { reactive,ref } from 'vue';
import Api from '../util/Api'
import { ImagePreview } from 'vant';
export default { 
  components: {
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
 setup() {
    const dataForm = reactive({
   
      tabs3_2: {
        searchKey: '',
        currentPage: 1,
        data: {
          total: 0,
          list: [
          //  {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
            ]
        }
      },
    });

    const dicPersonTypeLabel = ["其他情况", "红黄码人员", "中高风险地区返回", "市外返回", "省外返回", "境外人员", "阳性物品暴露人员", "次密接", "密接"]

    const dicPersonType = ['Other', 'RedAndYellow', 'HighRisk', "OutsideCity", "OutsideProvince", "Abroad", 'PositiveExposure', 'SecondTouch', 'Touch']

    const dicPersonTypeList = [{ value: 'Other', label: '其他情况' }, { value: 'RedAndYellow', label: '红黄码人员' }, { value: 'HighRisk', label: '中高风险地区返回' }, { value: 'OutsideCity', label: '市外返回' }, { value: 'OutsideProvince', label: '省外返回' }, { value: 'Abroad', label: '境外人员' }, { value: 'PositiveExposure', label: '阳性物品暴露人员' }, { value: 'SecondTouch', label: '次密接' }, { value: 'Touch', label: '密接' },]

    const SensorDataTypeArray = ["已关门", "故障", "已开门"]
    const SensorDeviceStateArray = ["正常", "故障", "报警", "离线"]
  const   isLoading=ref(false)
    return { dataForm,isLoading, dicPersonTypeLabel, dicPersonType, dicPersonTypeList,SensorDataTypeArray,SensorDeviceStateArray,ImagePreview  }
  },
  mounted() {
    this.$emit('changeTitle', "待核查人员信息")
    this.getTab3_2DateList(); 
  },
    activated() {
    this.getTab3_2DateList();
  
  },
  methods:
  {
  getDateList(){
       this.getTab3_2DateList(); 

  },
    onTabs3_2Search(val) {
      this.dataForm.tabs3_2.searchKey = val
      this.getTab3_2DateList()
    },
    getTab3_2DateList() {
      Api.getvhpage({ limit: 10, dataType: 2, page: this.dataForm.tabs3_2.currentPage, personName: this.dataForm.tabs3_2.searchKey, }).then(res => {
           this.isLoading=false
         if(res.code==0)
         this.dataForm.tabs3_2.data = res.data
        
      })
    },
    getDictLabel(type, value) {
        try{
      return this.$store.state.Dict.find(p => p.dictType == type).dataList.find(p => p.dictValue == value).dictLabel
      }catch{
         return ''

      }
    }

  }
}
</script>
<style scoped>
.dataList {
  background: #f8f8f8ff;
}
.dataItem {
  margin: 15px 0px 15px 0px;
  /* border: 1px solid rgb(212, 212, 212); */
  padding-top: 10px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(153, 153, 153, 0.15);
  border-radius: 4px;
 
}
.dataItem .r1 .title {
  color: #313836;
  padding-left: 15px;
  padding-right: 15px;
}
.dataItem .r1 .leftTag {
  margin-right: 5px;
}
.dataItem .r1 .rightTag {
  right: 25px;
  position: absolute;
}
.dataItem .r2 {
  padding: 5px;
  font-size: 12px;
  font-family: "微软雅黑";
  color: #3c3c3c;
  margin-top: 0.6em;
}
.dataItem .r2 .itemTitle {
  color: #999999;
}
.dataItem .r2 .itemValue {
  margin-top: 0.9em;
  color: #1b2026;
}

.dataItem .r2 .colMiddle {
  border-left: 1px solid #dfe6ff;
  border-right: 1px solid #dfe6ff;
}
.van-pull-refresh >>> .van-pull-refresh__track {
min-height: 90vh;

}
</style>